import { TermItemDefinition } from '@/services/term'
import { defineStore } from 'pinia'
export const useSearchMasterdataStore = defineStore('searchMasterdataStore', {
  state: () => ({
    legalEntities: true,
    farms: false,
    dairies: false,
    name: null as string | null,
    address: null as string | null,
    ident: null as string | null,
    contact: null as string | null,
    items: [
      { key: 'legalEntities', operation: ':' },
      { key: 'farms', operation: ':' },
      { key: 'dairies', operation: ':' },
      { key: 'name', operation: '~' },
      { key: 'address', operation: '~' },
      { key: 'ident', operation: '~' },
      { key: 'contact', operation: '~' }
    ] as TermItemDefinition[]
  })
})
