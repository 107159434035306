<template>
  <div>
    <h2 v-html="$t('search_generic_title')"></h2>
    <!-- Milchproduzent -->
    <v-switch id="search_generic_farms" v-model="searchCardStore.farms" color="primary" :label="$t('search_generic_farms')" @keyup.enter="apply()"></v-switch>
    <!-- Milchverwerter -->
    <v-switch
      id="search_generic_dairies"
      v-model="searchCardStore.dairies"
      color="primary"
      :label="$t('search_generic_dairies')"
      @keyup.enter="apply()"
    ></v-switch>
    <!-- Rechtliche Einheit -->
    <!-- <v-switch id="search_generic_legal_entities" v-model="searchCardStore.legalEntities" color="primary"  :label="$t('search_generic_legal_entities')" @keyup.enter="apply()"></v-switch> -->
    <!-- Name/Firma/Vorname/Zusatz/Anschrift/Postfach -->
    <v-text-field id="search_generic_name" v-model.trim="searchCardStore.name" :label="$t('search_generic_name')" type="text" @keyup.enter="search" />
    <!-- Strasse/PLZ/Ort/Kanton/Land -->
    <v-text-field id="search_generic_street" v-model.trim="searchCardStore.address" :label="$t('search_generic_street')" type="text" @keyup.enter="search" />
    <!-- Agate-Nr./AGIS-ID/MBH-ID/BUR/UID/TVD-Nr./Kreditor-Nr. -->
    <v-text-field
      id="search_generic_agate_nr"
      :rules="[$rules.isNumericList]"
      v-model.trim="searchCardStore.ident"
      :label="$t('search_generic_agate_nr')"
      type="text"
      @keyup.enter="search"
    />
    <!-- Tel-Nr., E-Mail -->
    <v-text-field
      id="search_generic_phone_nr"
      v-model.trim="searchCardStore.contact"
      :label="$t('search_generic_phone_nr')"
      type="text"
      @keyup.enter="search"
    />
  </div>
</template>

<script lang="ts">
import baseSearchVue from './baseSearch.vue'
import { useSearchMasterdataStore } from '@/store/SearchMasterdataStore'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'searchMasterdata',
  extends: baseSearchVue,
  data() {
    return {}
  },
  methods: {
    apply() {
      this.search()
    }
  },
  beforeMount() {
    this.setSearchCardStore(useSearchMasterdataStore(), true)
  }
})
</script>
